import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Fab,
  Grid,
  Input,
  Typography,
  Tooltip
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  obraAction,
  obraMedicaoAction
} from '../../_actions'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'
import { uploadFile } from '_services'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        sx={{ color: props.value != 0 ? "primary" : "#d9d9d9" }}
        variant="determinate"
        value={props.value != 0 ? props.value : 100} />
      <Box
        top={2}
        left={2}
        bottom={2}
        right={2}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="#000"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const ObraMedicaoMotivoModal = ({ modal, toggle, props, idObra, aprovaMedicao, medicao, status }) => {

  const formRef = React.createRef(null);

  const [motivo, setMotivo] = useState('');
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [openFile, setOpenFile] = useState(false);

  const dispatch = useDispatch()
  const { classes, className, obraEtapa, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idObra) {
      dispatch(obraAction.getObraById(idObra))
    }
  }, [])

  const handleUploadClickFile = (event) => {
    //event.persist(); // Persist the event
    setOpenFile(true)
    const file = event.target.files[0];
    uploadFile(file)
      .then((response) => {
        setFilesUploaded([...filesUploaded, { caminhoArquivo: response.toString() }])
        setOpenFile(false)
      })
      .catch((err) => {
        console.log(err)
        setOpenFile(false)
      })
  };

  const handleSubmit = () => {

    if (aprovaMedicao) {
      let obrasMedicoesAnexos = [
        {
          caminhoArquivo: filesUploaded[0]?.caminhoArquivo,
          idObraMedicao: medicao?.id
        }
      ]

      dispatch(obraMedicaoAction.editObraMedicaoAprova(medicao.id, props.obra.id, status, motivo, filesUploaded && filesUploaded.length > 0 ? obrasMedicoesAnexos : null));
    } else {
      dispatch(obraMedicaoAction.editObraMedicaoRecusa(medicao.id, props.obra.id, status, motivo));
    }

    toggle();
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{aprovaMedicao ? 'Aprovar Medição' : 'Reprovar Medição'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <CardHeader
                style={{ marginTop: 0, paddingTop: 0 }}
                subheader="Alterar o Status de uma Medição"
                title={<SegHeader />}
              />
              <div style={{ marginRight: 22, marginTop: 20, gap: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="body1" color="rgba(0, 0, 0, 0.6)">
                  Percentual correspondente a esta medição: </Typography>
                <CircularProgressWithLabel value={medicao?.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                  return accumulator + (object.percentual)
                }, 0)} />
              </div>
            </div>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={7} md={7} xl={7} xs={12} align="center">
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="motivo"
                    label="Informe o Motivo"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={(event) => setMotivo(event.target.value)}
                    value={motivo || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12} xl={4} align="center">
                  <Typography variant="body2" align="center" style={{ color: '#666666', fontSize: 12, marginTop: 5, marginBottom: 2 }} >
                    Enviar arquivo
                  </Typography>
                  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openFile}>
                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <CircularProgress color="primary" />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" color="textPrimary">
                          Enviando arquivo...
                        </Typography>
                      </Grid>
                    </Grid>
                  </Backdrop>
                  <Input
                    accept="files/*"
                    id="file"
                    type="file"
                    onChange={(e) => handleUploadClickFile(e)}
                  />

                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.obraMedicao.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraMedicao.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraMedicao.isLoading ? 'Salvando medição...' : 'Salvar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
