import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import moment from 'moment'

export const relatorioAction = {
  getRelatorioEmpresaOrcamentoEnviado,
  getRelatorioEmpresaOrcamentoEnviadoArquivo,
  getRelatorioEmpresaPorOrgao,
  getRelatorioEmpresaPorTipoServico,
  getRelatorioEmpresaOrgaoArquivo,
  getRelatorioEmpresaTipoServicoArquivo,
  getRelatorioUsuarios,
  getRelatorioUsuariosArquivo,
  getRelatorioOrcamentosArquivo,
  getRelatorioOcorrenciaObra,
  getRelatorioOcorrenciaObraArquivo,
  clear,
  clearAll,
}

function getRelatorioEmpresaPorTipoServico(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasPorTipoServico/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioEmpresaOrcamentoEnviadoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasOrcamentosEnviados/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_empresas_orcamentos_enviados' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioEmpresaOrcamentoEnviado(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasOrcamentosEnviados/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioEmpresaPorOrgao(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasPorOrgao/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioUsuarios(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/usuarios/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioOcorrenciaObra(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasObra' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioOcorrenciaObraArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasObra/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_ocorrencias_obra' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioUsuariosArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/usuarios/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_usuarios_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioEmpresaTipoServicoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasTiposServicos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_empresas_tipos_servicos_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioEmpresaOrgaoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasOrgaos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_empresas_orgaos_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioOrcamentosArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/orcamentos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_orcamentos_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
          dispatch(clear())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

export function changeRelatorioList(relatorio, totalRows) {
  return {
    type: 'FETCHED_ALL_RELATORIO',
    relatorio: relatorio,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'RELATORIO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'RELATORIO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'RELATORIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function isLoading() {
  return {
    type: 'RELATORIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'RELATORIO_NOTISLOADING'
  }
}
