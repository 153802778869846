import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { obraCobrancaAction } from '../../_actions'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'

export const ObraCobrancaEditConfirmationModal = ({ modal, toggle, props, idObra, idOrgao, valor, items, obraMedicao }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, empresa, ...rest } = props
  const { match: { params } } = props;

  const isLoading = useSelector(
    (state) => state.usuarioEmpresa.isLoading
  )

  const handleClose = () => {
    toggle();
  }

  const handleOpenFile = (link) => {
    window.open(link);
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      dataCobranca: moment().toISOString(),
      idEmpresa: props.authentication.user.usuariosEmpresas ? props.authentication.user.usuariosEmpresas[0].idEmpresa : 0,
      idObra: idObra,
      valor: valor,
      caminhoNota: props.obraCobranca.caminhoNota,
      obrasMedicoesCobrancas: items ? items.map(n => ({
        idObraMedicao: n.id
      })) : []
    }

    if (!params.id) {
      dispatch(obraCobrancaAction.createObraCobranca(payload, idOrgao))
      toggle()
    }
  };

  function Text() {
    return (
      <Typography variant="h5">
        <b>{'Resumo da Cobrança'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <Text />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastro de Cobranças"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F2F2F2' }}>
                      <TableCell size="small"><strong>DATA</strong></TableCell>
                      <TableCell size="small"><strong>ITEM</strong></TableCell>
                      <TableCell size="small"><strong>VALOR</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items && undefined !== items && items.length
                      ? items.map((n) => {
                        let valorItem = n.valor;
                        let percentual = n.obrasMedicoesEtapasItens[0]?.percentual;

                        const valor = valorItem * (percentual / 100);

                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              ({percentual}%)
                              {n ? ' ' + n.obrasMedicoesEtapasItens[0]?.obraEtapaItem?.descritivo : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </TableCell>
                          </TableRow >
                        )
                      })
                      :
                      (
                        !isLoading ?
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                          :
                          Array.apply(null, { length: 5 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                      )
                    }
                  </TableBody>
                  <TableHead>
                    <TableRow size="small" style={{ backgroundColor: '#F2F2F2' }}>
                      <TableCell size="small" colSpan={2}><strong>CAMINHO NOTA</strong></TableCell>
                      <TableCell size="small"><strong>VALOR TOTAL DO ORÇAMENTO</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover key={1}>
                      <Tooltip title="Clique aqui para visualizar o arquivo em uma nova aba">
                        <TableCell component="th" scope="row" colSpan={2} style={{ cursor: 'pointer' }} onClick={() => handleOpenFile(props.obraCobranca.caminhoNota)}>
                          {props.obraCobranca.caminhoNota}
                        </TableCell>
                      </Tooltip>
                      <TableCell component="th" scope="row">
                        {valor ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}
                      </TableCell>
                    </TableRow >
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <CustomButton
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  type="submit"
                  dark={true}
                  variant="contained"
                  disabled={props.obraCobranca.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraCobranca.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraCobranca.isLoading ? 'Salvando cobrança...' : 'Salvar cobrança'}
                </CustomButton>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
