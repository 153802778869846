export const stringUtils = {
  getObraParam,
  serialize,
  toInputUppercase,
  toInputLowerCase,
  removeDiacritics
};

function serialize(obj) {
  let str = '?' + Object.keys(obj).reduce(function (a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a;
  }, []).join('&');
  return str;
}

function getObraParam() {
  // Obtenha a parte dos parâmetros da URL (após o '?')
  const params = new URLSearchParams(window.location.search);
  
  // Verifique se o parâmetro 'obra' existe
  if (params.has('obra')) {
    // Obtenha o valor do parâmetro 'obra'
    const obraValue = params.get('obra').toLowerCase();

    // Faça o parse para booleano ou null
    if (obraValue === 'true') {
      return true;
    } else if (obraValue === 'false') {
      return false;
    }
  }else{
    return null;
  }
}

function toInputUppercase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(start, end);
}

function toInputLowerCase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toLowerCase();
  e.target.setSelectionRange(start, end);
}

export function removeDiacritics(palavra) {
  return palavra.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
