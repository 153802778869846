import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import { uploadFile } from '_services'
import {
  Backdrop,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  Input,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import CustomButton from '../../components/CustomButton/CustomButton'
import ChecklistIcon from '@mui/icons-material/Checklist';
import { obraCobrancaAction, obraMedicaoAction } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Page as PageForm } from 'components'
import PropTypes from 'prop-types'
import { withRouter, } from 'react-router-dom'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withStyles } from '@mui/styles'
import ObraSummaryCard from './../Obras/obraSummaryCard.component'
import { ObraCobrancaEditConfirmationModal } from '..';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ObraCobrancaEdit = (props) => {

  const { isLoading } = props.obraCobranca
  const { obraMedicao } = props.obraMedicao

  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const { classes, className, ...rest } = props

  const location = useLocation();
  const idObra = location.state;

  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch()
  const [valor, setValor] = useState(0);
  const [file, setFile] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [key, setKey] = useState(1);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [items, setItems] = useState([]);
  const [itemsValor, setItemsValor] = useState([]);

  useEffect(() => {
  }, [ready, isLoading])

  useEffect(() => {
    dispatch(obraCobrancaAction.clear())

    if (params.id) {
      dispatch(obraCobrancaAction.getObraCobrancaById(params.id))
    }

  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const clearCheckboxSelection = () => {
    setItems([]);
    setItemsValor([]);
  }

  const handleCheckboxSelectAll = () => {
    const newItems = [];
    const newItemsValor = [];

    obraMedicao && obraMedicao.length > 0 && obraMedicao.map((n) => {

      let valorItem = n.valor;
      let percentual = n.obrasMedicoesEtapasItens[0]?.percentual;

      const valor = valorItem * (percentual / 100);

      newItems.push(n);
      newItemsValor.push(valor);
    })

    setItems(newItems);
    setItemsValor(newItemsValor);
  }

  const handleCheckboxChange = (event, item, valor) => {
    const isChecked = event.target.checked;

    const updatedItems = [...items];
    const updatedItemsValor = [...itemsValor];

    if (isChecked) {
      updatedItems.push(item);
      updatedItemsValor.push(valor);
    } else {
      const index = updatedItems.indexOf(item);
      const indexValor = updatedItemsValor.indexOf(valor);
      if (index !== -1) {
        updatedItems.splice(index, 1);
        updatedItemsValor.splice(indexValor, 1);
      }
    }

    setItems(updatedItems);
    setItemsValor(updatedItemsValor);
  };

  useEffect(() => {
    if (itemsValor && itemsValor.length > 0) {
      const sum = itemsValor.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setValor(sum);
    } else {
      setValor(0);
    }
  }, [itemsValor])

  const upload = () => {
    setOpen(true)
    uploadFile(file)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(obraCobrancaAction.onChangeProps('caminhoNota', event2))

          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {

    //traz as aprovadas
    let filterModel = {
      Page: 0,
      IdObra: idObra,
      Situacao: 1,
      Status: 'semcobranca'
    };

    dispatch(obraMedicaoAction.getObraMedicao(filterModel, true));

  }, []);

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != undefined && file != null) {
      setOpen(true)
      upload()
    }
  }, [file])

  const handleOpenModalConfirmation = () => {
    if (items && items.length > 0) {
      if (props.obraCobranca.caminhoNota != '' && props.obraCobranca.caminhoNota != null && props.obraCobranca.caminhoNota != undefined) {
        toggle();
      } else {
        toast.error('É necessário anexar uma nota fiscal para prosseguir');
      }
    }
    else {
      toast.error('Selecione ao menos uma medição para prosseguir');
    }
  };

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SegTitle() {
    return <Title />
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Visualizar Cobrança'}</b>
      </Typography>
    )
  }

  function Title() {
    return (
      <Typography variant="h6" align="center">
        <b>{'Selecione os itens para as quais você deseja criar uma cobrança'}</b>
      </Typography>
    )
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Cobrança'}</b>
      </Typography>
    )
  }

  return (
    <PageForm
      className={classes.root}
      title="Gerência de Cobrança - Visualizar Cobrança"
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formObraCobranca"
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Cobrança"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                {!params.id ?
                  <CardContent>
                    {idObra &&
                      <ObraSummaryCard idObra={idObra} latitude={props.obra.latitude} longitude={props.obra.longitude} />
                    }
                    <CardHeader
                      title={<SegTitle />}
                    />
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell style={{ minWidth: '200px' }}>Valor</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {undefined !== obraMedicao && obraMedicao.length
                            ? obraMedicao.map((n) => {

                              let valorItem = n.valor;
                              let percentual = n.obrasMedicoesEtapasItens[0]?.percentual;

                              const valor = valorItem * (percentual / 100);

                              return (
                                <TableRow hover key={n.id}>
                                  <TableCell component="th" scope="row">
                                    {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    ({percentual}%)
                                    {n ? ' ' + n.obrasMedicoesEtapasItens[0]?.obraEtapaItem?.descritivo : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {valor ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Checkbox
                                      onChange={(event) => handleCheckboxChange(event, n, valor)}
                                      checked={items.includes(n)}
                                      color="primary"
                                      size="small"
                                    />
                                  </TableCell>
                                </TableRow >
                              )
                            })
                            :
                            (
                              !isLoading ?
                                <TableRow>
                                  <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                                :
                                Array.apply(null, { length: 5 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                            )
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Grid fullWidth item lg={12} md={12} xl={12} xs={12} display="flex" alignItems="flex-end" justifyContent="flex-end">
                      <CustomButton
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                        onClick={() => clearCheckboxSelection()}
                      >
                        limpar
                      </CustomButton>
                      <CustomButton
                        dark
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                        onClick={() => handleCheckboxSelectAll()}
                      >
                        TODOS
                      </CustomButton>
                    </Grid>

                    <Divider />
                    <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 15 }}>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando nota...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <label>Anexar nota *</label>
                        <Input
                          id="caminhoNota"
                          style={{ marginTop: 3 }}
                          label="Anexar nota *"
                          onChange={e => handleChangeFile(e)}
                          type="file"
                          variant="outlined"
                          margin="normal"
                          placeHolder="Escolher arquivo *"
                          formControl
                          key={key}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="valorCobranca"
                          label="Valor total da Cobrança"
                          margin="normal"
                          value={valor ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 || 0}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  :
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="dataCobranca"
                          label="Data da Cobrança"
                          margin="normal"
                          value={moment(props.obraCobranca.dataCobranca).format("DD/MM/YYYY") || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="dataPagamento"
                          label="Data do Pagamento"
                          margin="normal"
                          value={props.obraCobranca.dataPagamento ? moment(props.obraCobranca.dataPagamento).format("DD/MM/YYYY HH:mm") : ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="empresa"
                          label="Empresa"
                          margin="normal"
                          value={props.obraCobranca.empresa?.razaoSocial?.toUpperCase()}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6} xl={6} xs={12} >
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="obra"
                            label="Obra"
                            margin="normal"
                            value={
                              props.obraCobranca?.obra?.titulo?.toUpperCase() || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            multiline
                            readOnly
                            minRows={2}
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="descritivo"
                            label="Descritivo"
                            margin="normal"
                            value={
                              props.obraCobranca?.obra?.descritivo?.toUpperCase() || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="valor"
                            label="Valor"
                            margin="normal"
                            value={`${props.obraCobranca?.valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            label="Status"
                            margin="normal"
                            readOnly
                            variant="outlined"
                            value={props.obraCobranca.dataPagamento != null ? 'PAGO' : 'EM ABERTO'}
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={12} align="center">
                        <FormLabel>
                          <Typography variant="subtitle1" align="center">Nota Fiscal</Typography>
                        </FormLabel>
                        {props.obraCobranca.caminhoNota && (props.obraCobranca.caminhoNota.includes('.jpg') || props.obraCobranca.caminhoNota.includes('.jpeg') || props.obraCobranca.caminhoNota.includes('.png')) ?
                          <img
                            style={{ width: 350, height: 450, objectFit: 'contain', padding: 3, justifycontent: 'right', borderRadius: 7 }}
                            src={props.obraCobranca.caminhoNota}
                            alt="Image"
                          />
                          :
                          props.obraCobranca.caminhoNota && props.obraCobranca.caminhoNota.includes('.pdf') ?
                            <iframe
                              src={`https://docs.google.com/viewer?url=${props.obraCobranca.caminhoNota}&embedded=true`}
                              style={{ width: 450, height: 450, objectFit: 'contain', padding: 3, justifycontent: 'right', borderRadius: 7 }}
                              frameborder="0"
                              scrolling="no"
                            >
                            </iframe>
                            : null
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                }
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.push('/obrasCobrancas')}
                    >
                      Cancelar
                    </CustomButton>
                    {!params.id &&
                      <CustomButton
                        variant="contained"
                        dark={true}
                        type="submit"
                        onClick={() => handleOpenModalConfirmation()}
                        disabled={props.obraCobranca.isLoading}
                        startIcon={<ChecklistIcon />}
                      >
                        Revisar e enviar cobrança
                      </CustomButton>
                    }
                  </Grid>
                </CardActions>
              </ValidatorForm>
              {
                (modal ?
                  <ObraCobrancaEditConfirmationModal toggle={toggle} modal={modal} props={props} idObra={idObra} idOrgao={idOrgao} valor={valor} items={items} obraMedicao={obraMedicao} />
                  :
                  null
                )
              }
            </Card >
          </Grid >
          {
            (modalHelp ?
              <HelpModal
                toggle={toggleModalHelp}
                modal={modalHelp}
                props={props}
                tela={MANUAIS_CATEGORIAS.CADASTRO_COBRANCA}
              />
              :
              null
            )
          }
        </Grid >
      </Grid >
    </PageForm >
  )
};

ObraCobrancaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraCobrancaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraCobrancaEdit))
)
export { connectedObraCobrancaEditPage as ObraCobrancaEdit }

export default ObraCobrancaEdit;
