import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {
  Avatar,
  Grid,
  Link,
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Button,
  Skeleton,
  TablePagination,
  MenuItem,
  IconButton,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { insumoAction, obraEtapaItemAction } from '../../_actions'
import CloseIcon from '@mui/icons-material/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import { history } from 'utils'
import { useDebounce } from 'use-debounce';
import { isEmpty } from 'lodash'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { stringUtils } from '../../utils'

const styles = {
  //position: 'absolute',
  //top: '50%',
  //left: '50%',
  //transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const InsumoModal = ({ modal, toggle, props, ano, mes, uf, tipoTabela, onerado }) => {

  const dispatch = useDispatch()
  const { classes, ...rest } = props

  const { totalRegistros } = props.insumo

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);

  //const { idLoja } = useSelector(
  //  (state) => state.usuarioLoja
  //)
  const isLoadingInsumoModal = useSelector(
    (state) => state.insumo.isLoading
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico)

  const { unidade } = useSelector(
    (state) => state.unidade)

  const [nomeClasse, setNomeClasse] = useState('');
  const [nomeUnidade, setNomeUnidade] = useState('');

  useEffect(() => {
    dispatch(insumoAction.clear());
  }, []);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term.toUpperCase(),
        Order: order,
        Direction: direction,
        Ano: ano,
        Mes: mes,
        Uf: uf,
        TipoTabela: tipoTabela,
        NomeClasse: nomeClasse ? stringUtils.removeDiacritics(nomeClasse) : '',
        NomeUnidade: nomeUnidade
      };

      dispatch(insumoAction.getInsumos(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction, nomeClasse, nomeUnidade, ano, mes, uf);

  }, [page, limit, debounceTerm, order, direction, nomeClasse, nomeUnidade, ano, mes, uf]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.empresa.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleChangeUnidade = prop => event => {
    setNomeUnidade(event.target.value);
  };

  const handleChangeClasse = prop => event => {
    setNomeClasse(event.target.value);
  };

  const handleClick = (insumo) => {

    var event1 = {
      type: 'text',
      target: {
        value: insumo.codigo
      }
    };
    dispatch(obraEtapaItemAction.onChangeProps('codigo', event1))

    var event2 = {
      type: 'text',
      target: {
        value: insumo.nome
      }
    };
    dispatch(obraEtapaItemAction.onChangeProps('descritivo', event2))

    var filteredUnidades = props.unidade.unidade.filter(obj => obj.text == insumo.unidade.nome);
    if (filteredUnidades.length > 0) {

      var event3 = {
        type: 'selectValidator',
        target: {
          value: filteredUnidades[0].value
        }
      };
      dispatch(obraEtapaItemAction.onChangeProps('idUnidade', event3))

    }

    var filteredTiposServicos = props.tipoServico.tipoServico.filter(obj => stringUtils.removeDiacritics(obj.nome) == stringUtils.removeDiacritics(insumo.classe.nome));
    if (filteredTiposServicos.length > 0) {

      var event4 = {
        type: 'selectValidator',
        target: {
          value: filteredTiposServicos[0].id
        }
      };
      dispatch(obraEtapaItemAction.onChangeProps('idTipoServico', event4))

    }

    var event5 = {
      type: 'text',
      target: {
        value: (onerado ? insumo.valorOnerado : insumo.valorNaoOnerado)
      }
    };
    dispatch(obraEtapaItemAction.onChangeProps('valorReferencia', event5))

    toggle();
  }

  const handleClose = () => {
    toggle();
    history.push('/pedidosCompras');
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth={false}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              INSUMOS
            </Typography>
          </Grid>
          <AccessControl
            rule={'insumos.list'} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item>
                <Grid item xs={6} align="right">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#000' }}
                    onClick={toggle}
                  >
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        <>
          <ValidatorForm
            className={classes.form}
            id="formPesq"
          >
            <Grid container spacing={2}>

              <Grid item lg={4} md={4} xl={4} xs={12}>
                <SelectValidator
                  variant="outlined"
                  id="idTipoServico"
                  label="Tipo de Serviço"
                  margin="normal"
                  onChange={handleChangeClasse()}
                  value={nomeClasse}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="idTipoServico"
                >
                  <MenuItem disabled value="">
                    <em>Tipo de Serviço</em>
                  </MenuItem>
                  {!isEmpty(tipoServico) &&
                    undefined !== tipoServico &&
                    tipoServico.length &&
                    tipoServico.map((row) => (
                      <MenuItem key={row.id} value={row.nome}>
                        {row.nome}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={12}>
                <SelectValidator
                  variant="outlined"
                  id="idUnidade"
                  label="Unidade"
                  margin="normal"
                  onChange={handleChangeUnidade()}
                  value={nomeUnidade}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="idUnidade"
                >
                  <MenuItem disabled value="">
                    <em>Unidade</em>
                  </MenuItem>
                  {!isEmpty(unidade) &&
                    undefined !== unidade &&
                    unidade.length &&
                    unidade.map((row) => (
                      <MenuItem key={row.value} value={row.text}>
                        {row.text}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={handleChange('term')}
                  fullWidth
                  style={{ marginTop: 40 }}
                />
              </Grid>

            </Grid>
          </ValidatorForm>
          <br />
          <TableContainer>
            <Table id="tableResumo">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Classe</TableCell>
                  <TableCell>Unidade</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              {isLoadingInsumoModal ?
                <TableBody>
                  {Array.apply(null, { length: 10 }).map((e, i) => (
                    <TableRow hover key={i}>
                      <TableCell component="th" scope="row" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row" align="right" size={'small'}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
                :
                <TableBody>
                  {props.insumo != null && props.insumo != undefined && props.insumo.insumo != null && props.insumo.insumo != undefined ?
                    props.insumo.insumo.map((n) => {
                      return (
                        <TableRow hover key={(n ? n.id : 0)} >
                          <TableCell component="th" scope="row" size={'small'}>
                            {n.codigo}
                          </TableCell>
                          <TableCell component="th" scope="row" size={'small'}>
                            {n.nome}
                          </TableCell>
                          <TableCell component="th" scope="row" size={'small'}>
                            {(n.classe ? n.classe.nome : '')}
                          </TableCell>
                          <TableCell component="th" scope="row" size={'small'}>
                            {(n.unidade ? n.unidade.nome : '')}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right" size={'small'}>
                            {Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format((onerado ? n.valorOnerado : n.valorNaoOnerado))}
                          </TableCell>
                          <TableCell align="right" size={'small'}>

                            <IconButton
                              aria-label="Select"
                              component="a"
                              onClick={() => handleClick(n)}
                              size="small"
                            >
                              <Tooltip title={'Selecionar'}>
                                <Tooltip >
                                  <OpenInNewIcon />
                                </Tooltip>
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    <TableRow>
                      <TableCell component="th" scope="row" align="center" colSpan={6}>
                        Nenhum registro encontrado para o período selecionado
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRegistros}
            page={page}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeLimit}
          />
        </>
      </DialogContent>
      <DialogActions>
        {/*<StickyContainer>*/}
        {/*  <Sticky relative={false} isSticky={true}>*/}
        {/*{({ style }) => (*/}
        <>
          <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }} spacing={3}>
            {/*<AccessControl*/}
            {/*  rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo*/}
            {/*  yes={() => (*/}
            {/*    <Grid item>*/}
            {/*      <Grid item display="flex" flexDirection="row" justifyContent="right" style={{ marginLeft: 5 }}>*/}
            {/*        <Button*/}
            {/*          color="primary"*/}
            {/*          variant="contained"*/}
            {/*          disabled={props.insumo.isLoading}*/}
            {/*          startIcon={<SaveIcon />}*/}
            {/*          endIcon={props.insumo.isLoading && <CircularProgress size={24} />}*/}
            {/*          onClick={(event) => handleDownloadFile(event, props.insumo.id)}*/}
            {/*        >*/}
            {/*          {(props.insumo.isLoading ? 'Baixando...' : 'Baixar')}*/}
            {/*        </Button>*/}
            {/*      </Grid>*/}
            {/*    </Grid>*/}
            {/*  )}*/}
            {/*/>*/}
          </Grid>
        </>
        {/*)}*/}
        {/*  </Sticky>*/}
        {/*</StickyContainer>*/}
      </DialogActions>
    </Dialog >
  )
}
