import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, empresaAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import TitleContentInformation from '../TitleContentInformation'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioEmpresaOrcamentoEnviado = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [valueEmpresa, setValueEmpresa] = useState(null);
  const [inputValueEmpresa, setInputValueEmpresa] = useState('');
  const [openEmpresa, setOpenEmpresa] = useState(false)

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { empresa } = useSelector(
    (state) => state.empresa)

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  useEffect(() => {
    let filterModel = {
      IdOrgao: idOrgao
    }

    dispatch(empresaAction.getEmpresaSelect(filterModel));

  }, [idOrgao]);

  useEffect(() => {
    dispatch(relatorioAction.clear());
  }, [])

  const optionsEmpresas = empresa.map(u => ({ id: u.value, nome: u.text }));

  const handlePrint = () => {
    if (valueEmpresa && valueEmpresa != undefined) {
      window.open('/relatorioEmpresasOrcamentosEnviadosPrint', '_blank');
    }
  }

  const handleExportExcel = () => {

    let payload = {
      Id: valueEmpresa ? valueEmpresa.id : 0
    };

    dispatch(relatorioAction.getRelatorioEmpresaOrcamentoEnviadoArquivo(payload))
  }

  const handleSubmit = (event) => {
    if (valueEmpresa && valueEmpresa != undefined) {

      let payload = {
        Id: valueEmpresa ? valueEmpresa.id : 0,
      };

      dispatch(relatorioAction.getRelatorioEmpresaOrcamentoEnviado(payload, true));
    } else {
      toast.error('Selecione uma empresa!')
    }
  };

  useEffect(() => {
    if (valueEmpresa) {
      handleChangeAutoCompleteEmpresa(valueEmpresa)
    }
  }, [valueEmpresa])

  const handleChangeAutoCompleteEmpresa = (obj) => {
    if (obj != null) {
      let selectedEmpresa = empresa.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        empresaAction.changeDetailsEmpresa(
          selectedEmpresa[0]
        )
      )
    } else {

    }
    setOpenEmpresa(false)
  }

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Orçamentos enviados por uma Empresa">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioOrcamentosEnviadosEmpresa"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Orçamentos enviados por uma Empresa'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={7} md={7} xl={7} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueEmpresa}
                          onChange={(event, newValue) => {
                            setValueEmpresa(newValue);
                          }}
                          inputValue={inputValueEmpresa}
                          onInputChange={(event, newInputValueEmpresa) => {
                            setInputValueEmpresa(newInputValueEmpresa);
                          }}
                          id="controllable-states-demo"
                          options={optionsEmpresas}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueEmpresa}
                          getOptionLabel={(o) => (o ? o.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Empresa *... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell><strong>OBRA </strong></TableCell>
                        <TableCell><strong>ÓRGÃO</strong></TableCell>
                        <TableCell><strong>VALOR</strong></TableCell>
                        <TableCell><strong>STATUS</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataHora ? moment(n.dataHora).format('DD/MM/yyyy HH:mm') : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.tituloObra ? n.tituloObra.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.orgao ? n.orgao.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.valor ? n.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.status ? n.status.toUpperCase() : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (valueEmpresa != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={5} align="center">Informe uma empresa</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={4}><b>TOTAL DE ORÇAMENTOS</b></TableCell>
                          <TableCell rowSpan={2}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.RELATORIO_EMPRESA_ORCAMENTO_ENVIADO}
          />
          :
          null
        )
      }
    </Page>
  )
}
RelatorioEmpresaOrcamentoEnviado.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioEmpresaOrcamentoEnviadoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioEmpresaOrcamentoEnviado))
)
export { connectedRelatorioEmpresaOrcamentoEnviadoPage as RelatorioEmpresaOrcamentoEnviado }
