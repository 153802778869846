import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts'

import {
  Bdi as BdiView,
  Diario as DiarioView,
  CalcMat as CalcMatView,
  Error404 as Error404View,
  EmailVerification as EmailVerificationView,
  Empenho as EmpenhoView,
  EmpenhoEdit as EmpenhoEditView,
  Empresa as EmpresaView,
  EmpresaAnexo as EmpresaAnexoView,
  EmpresaEdit as EmpresaEditView,
  EmpresaUsuarioEdit as EmpresaUsuarioEditView,
  Home as HomeView,
  Manual as ManualView,
  ManualEdit as ManualEditView,
  LinksUteis as LinksUteisView,
  Login as LoginView,
  Notificacao as NotificacaoView,
  NotificacaoEdit as NotificacaoEditView,
  Obra as ObraView,
  ObraMateriais as ObraMateriaisView,
  ObraPlanoTrabalho as ObraPlanoTrabalhoView,
  ObraReparoPrevencao as ObraReparoPrevencaoView,
  ObraEdit as ObraEditView,
  ObraAguardandoOrcamento as ObraAguardandoOrcamentoView,
  ObraCobranca as ObraCobrancaView,
  ObraCobrancaEdit as ObraCobrancaEditView,
  ObraOrcamento as ObraOrcamentoView,
  ObraOrcamentoEdit as ObraOrcamentoEditView,
  ObraMedicao as ObraMedicaoView,
  ObraMedicaoListObrasEtapasItens as ObraMedicaoListObrasEtapasItensView,
  Orgao as OrgaoView,
  OrgaoEdit as OrgaoEditView,
  OrgaoUsuarioEdit as OrgaoUsuarioEditView,
  Parametro as ParametroView,
  ParametroEdit as ParametroEditView,
  PasswordReset as PasswordResetView,
  PasswordResetSuccess as PasswordResetSuccessView,
  PoliticaPrivacidade as PoliticaPrivacidadeView,
  MapaPublico as MapaPublicoView,
  RelatorioOcorrenciaObra as RelatorioOcorrenciaObraView,
  RelatorioOcorrenciaObraPrint as RelatorioOcorrenciaObraPrintView,
  RelatorioEmpresaOrcamentoEnviado as RelatorioEmpresaOrcamentoEnviadoView,
  RelatorioEmpresaOrcamentoEnviadoPrint as RelatorioEmpresaOrcamentoEnviadoPrintView,
  RelatorioEmpresaPorOrgao as RelatorioEmpresaPorOrgaoView,
  RelatorioEmpresaPorOrgaoPrint as RelatorioEmpresaPorOrgaoPrintView,
  RelatorioEmpresaPorTipoServico as RelatorioEmpresaPorTipoServicoView,
  RelatorioEmpresaPorTipoServicoPrint as RelatorioEmpresaPorTipoServicoPrintView,
  RelatorioUsuarios as RelatorioUsuariosView,
  RelatorioUsuariosPrint as RelatorioUsuariosPrintView,
  SubOrgao as SubOrgaoView,
  SubOrgaoEdit as SubOrgaoEditView,
  Dashboard as DashboardView,
  Usuario as UsuarioView,
  UsuarioEdit as UsuarioEditView
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <PrivateRouteWithLayout
        component={ManualView}
        exact
        layout={MainLayout}
        path="/manuais"
      />
      <PrivateRouteWithLayout
        component={ManualEditView}
        exact
        layout={MainLayout}
        path="/manual"
      />
      <PrivateRouteWithLayout
        component={ManualEditView}
        exact
        layout={MainLayout}
        path="/manual/:id"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoView}
        exact
        layout={MainLayout}
        path="/subOrgaos"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoEditView}
        exact
        layout={MainLayout}
        path="/subOrgao"
      />
      <PrivateRouteWithLayout
        component={SubOrgaoEditView}
        exact
        layout={MainLayout}
        path="/subOrgao/:id"
      />
      <PrivateRouteWithLayout
        component={NotificacaoView}
        exact
        layout={MainLayout}
        path="/notificacoes"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        exact
        layout={MainLayout}
        path="/notificacao"
      />
      <PrivateRouteWithLayout
        component={NotificacaoEditView}
        exact
        layout={MainLayout}
        path="/notificacao/:id"
      />
      <Route
        component={PoliticaPrivacidadeView}
        exact
        layout={MainLayout}
        path="/politica-de-privacidade"
      />
      <Route
        component={MapaPublicoView}
        exact
        layout={MainLayout}
        path="/mapa-publico"
      />
      <PrivateRouteWithLayout
        component={UsuarioView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario/:id"
      />
      <PrivateRouteWithLayout
        component={EmpresaView}
        exact
        layout={MainLayout}
        path="/empresas"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa"
      />
      <Route
        component={EmpresaUsuarioEditView}
        exact
        layout={MainLayout}
        path="/empresaCadastro"
      />
      <PrivateRouteWithLayout
        component={EmpenhoView}
        exact
        layout={MainLayout}
        path="/empenhos"
      />
      <PrivateRouteWithLayout
        component={EmpenhoEditView}
        exact
        layout={MainLayout}
        path="/empenho"
      />
      <PrivateRouteWithLayout
        component={EmpenhoEditView}
        exact
        layout={MainLayout}
        path="/empenho/:id"
      />
      <Route
        component={OrgaoUsuarioEditView}
        exact
        layout={MainLayout}
        path="/orgaoCadastro"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa/:id"
      />
      <PrivateRouteWithLayout
        component={OrgaoView}
        exact
        layout={MainLayout}
        path="/orgaos"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        layout={MainLayout}
        path="/orgao"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        path="/orgao/:id"
      />
      <PrivateRouteWithLayout
        component={ParametroView}
        exact
        layout={MainLayout}
        path="/parametros"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro/:id"
      />
      <PrivateRouteWithLayout
        component={RelatorioOcorrenciaObraView}
        exact
        layout={MainLayout}
        path="/relatorioOcorrenciasObra"
      />
      <PrivateRouteWithLayout
        component={RelatorioOcorrenciaObraPrintView}
        exact
        layout={MainLayout}
        path="/relatorioOcorrenciasObraPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaOrcamentoEnviadoView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresasOrcamentosEnviados"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaOrcamentoEnviadoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresasOrcamentosEnviadosPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorOrgaoView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresasPorOrgao"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorOrgaoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresaPorOrgaoPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorTipoServicoView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresasPorTipoServico"
      />
      <PrivateRouteWithLayout
        component={RelatorioEmpresaPorTipoServicoPrintView}
        exact
        layout={MainLayout}
        path="/relatorioEmpresaPorTipoServicoPrint"
      />
      <PrivateRouteWithLayout
        component={RelatorioUsuariosView}
        exact
        layout={MainLayout}
        path="/relatorioUsuarios"
      />
      <PrivateRouteWithLayout
        component={RelatorioUsuariosPrintView}
        exact
        layout={MainLayout}
        path="/relatorioUsuariosPrint"
      />
      <PrivateRouteWithLayout
        component={ObraView}
        exact
        layout={MainLayout}
        path="/obras"
      />
      <PrivateRouteWithLayout
        component={ObraMateriaisView}
        exact
        layout={MainLayout}
        path="/materiais"
      />
      <PrivateRouteWithLayout
        component={ObraPlanoTrabalhoView}
        exact
        layout={MainLayout}
        path="/planosTrabalhos"
      />
      <PrivateRouteWithLayout
        component={ObraReparoPrevencaoView}
        exact
        layout={MainLayout}
        path="/reparosPrevencoes"
      />
      <PrivateRouteWithLayout
        component={ObraMedicaoView}
        exact
        layout={MainLayout}
        path="/obrasCronogramas"
      />
      <PrivateRouteWithLayout
        component={EmpresaAnexoView}
        exact
        layout={MainLayout}
        path="/empresasAnexos"
      />
      <PrivateRouteWithLayout
        component={ObraMedicaoListObrasEtapasItensView}
        exact
        layout={MainLayout}
        path="/obrasCronogramasObrasEtapasItens"
      />
      <PrivateRouteWithLayout
        component={ObraAguardandoOrcamentoView}
        exact
        layout={MainLayout}
        path="/obrasAguardandoOrcamento"
      />
      <PrivateRouteWithLayout
        component={ObraCobrancaView}
        exact
        layout={MainLayout}
        path="/obrasCobrancas"
      />
      <PrivateRouteWithLayout
        component={ObraCobrancaEditView}
        exact
        layout={MainLayout}
        path="/obraCobranca/:id"
      />
      <PrivateRouteWithLayout
        component={ObraCobrancaEditView}
        exact
        layout={MainLayout}
        path="/obraCobranca"
      />
      <PrivateRouteWithLayout
        component={ObraEditView}
        exact
        layout={MainLayout}
        path="/obra"
      />
      <PrivateRouteWithLayout
        component={ObraEditView}
        exact
        layout={MainLayout}
        path="/obra/:id"
      />
      <PrivateRouteWithLayout
        component={ObraOrcamentoView}
        exact
        layout={MainLayout}
        path="/obrasOrcamentos"
      />
      <PrivateRouteWithLayout
        component={ObraOrcamentoEditView}
        exact
        layout={MainLayout}
        path="/obraOrcamento"
      />
      <PrivateRouteWithLayout
        component={ObraOrcamentoEditView}
        exact
        layout={MainLayout}
        path="/obraOrcamento/:id"
      />
      <PrivateRouteWithLayout
        component={BdiView}
        exact
        layout={MainLayout}
        path="/bdi"
      />
      <PrivateRouteWithLayout
        component={DiarioView}
        exact
        layout={MainLayout}
        path="/diario"
      />
      <PrivateRouteWithLayout
        component={LinksUteisView}
        exact
        layout={MainLayout}
        path="/links-uteis"
      />
      <PrivateRouteWithLayout
        component={CalcMatView}
        exact
        layout={MainLayout}
        path="/calcMat"
      />
      <Route component={LoginView} exact path="/login" />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <Route
        component={PasswordResetView}
        exact
        path="/password-reset"
      />
      <Route
        component={PasswordResetSuccessView}
        exact
        path="/password-reset-success"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
