import { crudService } from '../_services'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const obraEtapaAction = {
  getObraEtapa,
  getObraEtapaSelect,
  getObraEtapaById,
  onChangeProps,
  editObraEtapaInfo,
  createObraEtapa,
  deleteObraEtapaById,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraEtapa(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasEtapas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasEtapasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getObraEtapaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasEtapas/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObrasEtapasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraEtapa(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasEtapas'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(notIsLoading())
          dispatch(clear())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };
          dispatch(obraEtapaAction.getObraEtapa(filterModel, true));

        } else {
          // toast.error('Oops! Erro ao cadastrar ObraEtapa! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar ObraEtapa! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ETAPA_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ETAPA_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_ETAPA_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_ETAPA_CLEAR_DETAIL'
  }
}

function editObraEtapaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasEtapas/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          dispatch(clear())
          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };
          dispatch(obraEtapaAction.getObraEtapa(filterModel, true));
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ETAPA_FAILURE', error }
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_ETAPA_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_ETAPA_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_ETAPA_FAILURE', error }
}


function getObraEtapaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasEtapas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObrasEtapasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'obraEtapaItem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraEtapaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasEtapas/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObrasEtapasDetails())
      dispatch(notIsLoading())

      var ret = JSON.parse(response.request.responseText);
      let filterModel = {
        IdObra: ret.key
      };

      dispatch(obraEtapaAction.getObraEtapa(filterModel, true));

    })
  }
}
export function changeObrasEtapasList(obraEtapa, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_ETAPA',
    obraEtapa: obraEtapa,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_ETAPA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObrasEtapasDetails(obraEtapa) {
  return {
    type: 'OBRA_ETAPA_DETAIL',
    id: obraEtapa.id,
    descritivo: obraEtapa.descritivo,
    titulo: obraEtapa.titulo,
    periodos: obraEtapa.periodos,
    idObra: obraEtapa.idObra,
    dataInicio: obraEtapa.dataInicio,
    dataTermino: obraEtapa.dataTermino,
    obra: obraEtapa.obra,
    obrasEtapasItens: obraEtapa.obrasEtapasItens
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_ETAPA_CREATED_SUCCESSFULLY'
  }
}
export function deleteObrasEtapasDetails() {
  return {
    type: 'DELETED_OBRA_ETAPA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ETAPA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_ETAPA_NOTISLOADING'
  }
}
