import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles'
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
  Input,
  MenuItem
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  estadoAction,
  obraEtapaItemAction,
  obraEtapaAction,
  obraAction,
  tabelaInsumoAction,
  tabelaInsumoMesAction,
  tabelaInsumoAnoAction,
  tipoServicoAction,
  unidadeAction,
  authActions
} from '../../_actions'
import { withRouter, } from 'react-router-dom'
import { stringUtils, MANUAIS_CATEGORIAS, STATUS_SERVICO, TIPO_OBRA } from '../../utils'
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../../components/Utils/AccessControl'
import { isEmpty } from 'lodash'
import SearchIcon from '@mui/icons-material/Search';
import { InsumoModal } from '../Insumos/insumoModal.component';
import { toast } from 'react-toastify'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const ObraEtapaItemEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { classes, idObra, ...rest } = props
  const { totalRegistros } = props.obraEtapa
  const { isLoading } = props.obra
  const isObra = props.obra.tipoObra.obra;

  const formRef = React.createRef(null);
  const { match: { params } } = props;
  const [modalConfirm, setModalConfirm] = useState(false);

  const [itensObra, setItensObra] = useState([]);

  const [disabledEdicao, setDisabledEdicao] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();

  const [idObraEtapa, setIdObraEtapa] = useState(0);

  const { obraEtapa } = useSelector(
    (state) => state.obraEtapa)

  const { obraEtapaItem } = useSelector(
    (state) => state.obraEtapaItem)

  const { bdi, valorReferenciaComBdi } = useSelector(
    (state) => state.obra
  )

  const orgaoSelecionado = useSelector(
    (state) => state.usuarioOrgao?.orgao
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico)

  const { unidade } = useSelector(
    (state) => state.unidade)

  const { estado } = useSelector(
    (state) => state.estado)

  const { tabelaInsumo } = useSelector(
    (state) => state.tabelaInsumo)

  const estados = useSelector((state) => state.estado.estado)

  const { tabelaInsumoAno } = useSelector(
    (state) => state.tabelaInsumoAno)

  const { tabelaInsumoMes } = useSelector(
    (state) => state.tabelaInsumoMes)

  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(0);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  useEffect(() => {

    dispatch(obraEtapaItemAction.clear());    
    dispatch(unidadeAction.getUnidadesSelect());
    dispatch(estadoAction.getEstadoSelect());
    dispatch(tabelaInsumoAction.getTabelasInsumosSelect())
  }, []);

  useEffect(() => {
    if (props.obra.tipoObra.id == TIPO_OBRA.PLANO_TRABALHO || props.obra.tipoObra.id == TIPO_OBRA.PROJETO_TECNICO) {
      dispatch(tipoServicoAction.getTiposServico({ Page: 0, Limit: 100, IsProjeto: true }))
    } else {
      dispatch(tipoServicoAction.getTiposServico({ Page: 0, Limit: 100, IsProjeto: false }))
    }

  }, [props.obra?.tipoObra?.id])

  useEffect(() => {
    if (orgaoSelecionado && orgaoSelecionado.estado) {
      const fakeEvent = {
        type: 'text',
        target: {
          value: orgaoSelecionado.estado.id
        }
      }
      dispatch(obraEtapaItemAction.onChangeProps('idEstadoTabelaInsumo', fakeEvent))
    }
  }, [orgaoSelecionado])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      if (idObra) {
        let filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          IdObra: idObra
        };

        dispatch(obraEtapaAction.getObraEtapa(filterModel, true));
      }
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idObra]);

  function isEditable(e) {
    if (e == '1' || e == '2' || e == '3' || e == '4' || e == '5' || e == '6') {
      return false
    }
    else return true
  }


  useEffect(() => {
    dispatch(obraEtapaItemAction.clearCampos())
  }, [props.obraEtapaItem.idTabelaInsumo])

  useEffect(() => {

    if (props.obraEtapaItem.idTabelaInsumo != 0) {
      if (props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) {
        setDisabledEdicao(true);
      } else {
        setDisabledEdicao(false);
      }
    }

    if (props.obra.orgao.estado.id) {
      let changeEstadoEvent = {
        type: 'text',
        target: {
          value: props.obra.orgao.estado.id
        }
      };

      dispatch(obraEtapaItemAction.onChangeProps('idEstadoTabelaInsumo', changeEstadoEvent));

      let changeTabelaEvent = {
        type: 'text',
        target: {
          value: 1
        }
      };

      dispatch(obraEtapaItemAction.onChangeProps('idTabelaInsumo', changeTabelaEvent));

      let filterModel = {
        Uf: props.obra.orgao.estado.uf,
        TipoTabela: 'SINAPI'
      };
      dispatch(tabelaInsumoAnoAction.getAnosImportadosSelect(filterModel));
    }

  }, [])  //PRIMEIRA VEZ QUE CARREGA A TELA, PEGA OS ANOS QUE JÁ FORAM IMPORTADOS

  useEffect(() => {
    if (!props.obraEtapaItem.anoTabelaInsumo && props.obraEtapaItem.anoTabelaInsumo == 0) {
      if (tabelaInsumoAno && tabelaInsumoAno.length > 0 && Array.isArray(tabelaInsumoAno)) {
        let changeTabelaEvent = {
          type: 'text',
          target: {
            value: tabelaInsumoAno[0].text
          }
        };

        dispatch(obraEtapaItemAction.onChangeProps('anoTabelaInsumo', changeTabelaEvent));
      }
    }
  }, [tabelaInsumoAno]) //PREENCHE A COMBO DE ANOS

  useEffect(() => {
    let filterModel = null;
    if (!props.obraEtapaItem.mesTabelaInsumo && props.obraEtapaItem.mesTabelaInsumo == 0 || Number.isNaN(props.obraEtapaItem.mesTabelaInsumo)) {
      if (props.obraEtapaItem.anoTabelaInsumo != 0 && props.obraEtapaItem.idEstadoTabelaInsumo != 0 && props.obraEtapaItem.idTabelaInsumo != 0 && tabelaInsumo && tabelaInsumo != undefined) {
        filterModel = {
          Ano: props.obraEtapaItem.anoTabelaInsumo,
          Uf: (estado != null && estado != undefined && estado.length > 0 && props.obraEtapaItem.idEstadoTabelaInsumo != 0 && estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0] != null ?
            estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0].text
            : ''),
          TipoTabela: (tabelaInsumo != null && tabelaInsumo.length > 0 && tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0] != null ?
            tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0].text
            : '')
        };

        dispatch(tabelaInsumoMesAction.getMesesImportadosSelect(filterModel));

        if (tabelaInsumoMes && tabelaInsumoMes.length > 0 && tabelaInsumoMes[0].value) {
          let changeMesEvent = {
            type: 'text',
            target: {
              value: tabelaInsumoMes[0].value
            }
          };
          dispatch(obraEtapaItemAction.onChangeProps('mesTabelaInsumo', changeMesEvent));
        }
      }
    }

  }, [tabelaInsumoMes])

  useEffect(() => {

    let filterModel = null;
    if (props.obraEtapaItem.idTabelaInsumo != 0 && props.obraEtapaItem.idEstadoTabelaInsumo != 0 && props.obraEtapaItem.anoTabelaInsumo != 0) {
      filterModel = {
        Ano: props.obraEtapaItem.anoTabelaInsumo,
        Uf: (estado != null && estado != undefined && estado.length > 0 && props.obraEtapaItem.idEstadoTabelaInsumo != 0 && estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0] != null ?
          estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0].text
          : ''),
        TipoTabela: (tabelaInsumo != null && tabelaInsumo.length > 0 && tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0] != null ?
          tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0].text
          : '')
      };

      dispatch(tabelaInsumoMesAction.getMesesImportadosSelect(filterModel));

      let changeMesEvent = {
        type: 'text',
        target: {
          value: parseInt(tabelaInsumoMes[0]?.value)
        }
      };

      dispatch(obraEtapaItemAction.onChangeProps('mesTabelaInsumo', changeMesEvent));


      filterModel = {
        Ano: props.obraEtapaItem.anoTabelaInsumo,
        Uf: (estado != null && estado != undefined && estado.length > 0 && props.obraEtapaItem.idEstadoTabelaInsumo != 0 && estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0] != null ?
          estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0].text
          : ''),
        TipoTabela: (tabelaInsumo != null && tabelaInsumo.length > 0 && tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0] != null ?
          tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0].text
          : '')
      };

      dispatch(tabelaInsumoAnoAction.getAnosImportadosSelect(filterModel));

      let changeAnoEvent = {
        type: 'text',
        target: {
          value: props.obraEtapaItem.anoTabelaInsumo
        }
      };

      dispatch(obraEtapaItemAction.onChangeProps('anoTabelaInsumo', changeAnoEvent));

    }

  }, [props.obraEtapaItem.idTabelaInsumo, props.obraEtapaItem.idEstadoTabelaInsumo, props.obraEtapaItem.anoTabelaInsumo])

  useEffect(() => {
    if (props.obraEtapaItem.idTabelaInsumo) {
      if (props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) {
        setDisabledEdicao(true);
      } else {
        setDisabledEdicao(false);
      }
    }

  }, [props.obraEtapaItem.idTabelaInsumo])

  useEffect(() => {

    if (idObraEtapa != 0 && idObraEtapa != undefined) {
      const fetchData = (page, limit, term) => {

        let filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          Id: idObraEtapa
        };

        dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));

      };

      fetchData(page, limit, debounceTerm, order, direction);
    }

  }, [page, limit, debounceTerm, order, direction, idObraEtapa]);

  useEffect(() => {

    if (idObraEtapa != 0 && idObraEtapa != undefined) {
      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        Id: idObraEtapa
      };

      dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));

      handleCalculaValorReferenciaTotal();
      handleCalculaValorReferenciaTotalObra();
    }
  }, []);

  useEffect(() => {

    if (obraEtapa && obraEtapa != undefined && obraEtapa.length > 0 && idObraEtapa == 0) {
      setIdObraEtapa(obraEtapa[0].id)
    }
  }, [obraEtapa]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {

    updateItensObra();

  }, [obraEtapaItem])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(obraEtapaItemAction.getObraEtapaItemById(id))
  }

  const handleClean = () => {
    dispatch(obraEtapaItemAction.clearDetail())
  }

  const handleFinalizaLancamentoItens = (id) => {
    confirm({
      title: 'Você deseja concluir o lançamento dos itens?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Concluindo...' : 'Sim, concluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAction.editObraFinalizaLancamentoItens(id, idObra, idObraEtapa))
      toggleConfirm();
    })

  }

  const handleAlteraStatusParaEmLancamento = (id) => {
    confirm({
      title: 'Você deseja retornar a obra para o status anterior (Em lançamento)?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Retornando status...' : 'Sim, retornar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAction.editObraAlteraStatusEmLancamento(id))
    })
  }

  const handleDelete = (id) => {
    confirm({
      title: 'Você deseja excluir este item?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Excluindo item...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraEtapaItemAction.deleteObraEtapaItemById(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(obraEtapaItemAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: props.obraEtapaItem.id,
      idUnidade: props.obraEtapaItem.idUnidade,
      idTipoServico: props.obraEtapaItem.idTipoServico,
      idObraEtapa: parseInt(idObraEtapa),
      descritivo: props.obraEtapaItem.descritivo,
      codigo: props.obraEtapaItem.codigo,
      quantidade: props.obraEtapaItem.quantidade,
      idTabelaInsumo: props.obraEtapaItem.idTabelaInsumo,
      anoTabelaInsumo: (props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) ? (props.obraEtapaItem.anoTabelaInsumo != 0 && props.obraEtapaItem.anoTabelaInsumo != '' && parseInt(props.obraEtapaItem.anoTabelaInsumo)) : year,
      mesTabelaInsumo: (props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) ? (props.obraEtapaItem.mesTabelaInsumo != 0 && props.obraEtapaItem.mesTabelaInsumo != '' && parseInt(props.obraEtapaItem.mesTabelaInsumo)) : month,
      idEstadoTabelaInsumo: props.obra.orgao.estado.id,
      valorReferencia: props.obraEtapaItem.valorReferencia,
      onerado: props.obra.onerado
    }

    if (props.obraEtapaItem.quantidade != 0 && props.obraEtapaItem.quantidade != '') {
      if ((props.obraEtapaItem.idTabelaInsumo == 3 || props.obraEtapaItem.idTabelaInsumo == 4) || (props.obraEtapaItem.valorReferencia != 0 && props.obraEtapaItem.valorReferencia != null)) {
        if (props.obraEtapaItem.id) {
          dispatch(obraEtapaItemAction.editObraEtapaItemInfo(props.obraEtapaItem.id, payload, true))
        } else {
          dispatch(obraEtapaItemAction.createObraEtapaItem(payload))
        }
      } else {
        toast.error('Digite um valor referência para o item')
      }
    } else {
      toast.error('Digite uma quantidade para o item')
    }

    updateItensObra();

  };

  const updateItensObra = () => {

    try {

      if (idObra) {
        let filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          IdObra: idObra
        };

        dispatch(obraEtapaAction.getObraEtapa(filterModel, true));
      }

      //juntar itens das etapas
      const itensTotais = obraEtapa;
      const itens = itensTotais.reduce((acc, etapa) => {
        // Concatenando os itens de todas as etapas em um único array
        return acc.concat(etapa.obrasEtapasItens);
      }, []);

      setItensObra(itens);

    } catch (err) {
      console.log('ERR', err);
    }
  }

  const handleModalConfirm = (event) => {

    updateItensObra();

    setModalConfirm(true);

  }

  useEffect(() => {
    if (obraEtapaItem && obraEtapaItem.length > 0) {
      handleCalculaValorReferenciaTotal()
    }
  }, [obraEtapaItem.length])

  const handleCalculaValorReferenciaTotal = () => {
    const arrayValorTotal = obraEtapaItem && obraEtapaItem?.map((n) => {
      return (
        n.quantidade * n.valorReferencia
      )
    })

    if (arrayValorTotal && arrayValorTotal.length > 0) {
      var valorTotalItens = arrayValorTotal.reduce(function (accumulator, value) {
        return accumulator + value
      }, 0);

      return valorTotalItens != 0 ? Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(valorTotalItens) : null;
    }

    return null;
  }

  const handleCalculaValorReferenciaTotalObra = () => {

    const arrayValorTotal = itensObra?.map((n) => {
      return (
        n.quantidade * n.valorReferencia
      )
    })

    // && props.obra.bdi && props.obra.bdi != null && props.obra.bdi != 0
    if (arrayValorTotal && arrayValorTotal.length > 0) {
      var valorTotalItens = arrayValorTotal.reduce(function (accumulator, value) {
        return accumulator + value
      }, 0);


      return valorTotalItens != 0 ? Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(valorTotalItens + (props.obra.bdi ? (valorTotalItens * props.obra.bdi / 100) : 0)) : null;
    }

    return null;
  }

  const handleCalculaValorReferenciaTotalObraSemBdi = () => {

    const arrayValorTotal = itensObra?.map((n) => {
      return (
        n.quantidade * n.valorReferencia
      )
    })

    // && props.obra.bdi && props.obra.bdi != null && props.obra.bdi != 0
    if (arrayValorTotal && arrayValorTotal.length > 0) {
      var valorTotalItens = arrayValorTotal.reduce(function (accumulator, value) {
        return accumulator + value
      }, 0);

      return valorTotalItens != 0 ? Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(valorTotalItens) : null;
    }

    return null;
  }

  const handleOpenModal = () => {
    if (props.obraEtapaItem.idTabelaInsumo == 0 || props.obraEtapaItem.idTabelaInsumo == null) {
      toast.error('Informe a Tabela Padrão no cadastro do item!')
    }
    else if ((props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) && props.obraEtapaItem.anoTabelaInsumo == 0 || props.obraEtapaItem.anoTabelaInsumo == null) {
      toast.error('Informe o ano da Tabela no cadastro do item!')
    }
    else if ((props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4) && (props.obraEtapaItem.mesTabelaInsumo == 0 || props.obraEtapaItem.mesTabelaInsumo == null || props.obraEtapaItem.mesTabelaInsumo == '')) {
      toast.error('Informe o mês da Tabela no cadastro do item!')
    }
    else {
      toggle();

      if (props.obra.tipoObra?.id == TIPO_OBRA.PLANO_TRABALHO || props.obra.tipoObra?.id == TIPO_OBRA.PROJETO_TECNICO) {
        dispatch(tipoServicoAction.getTiposServico({ Page: 0, Limit: 100, IsProjeto: true }))
      } else {
        dispatch(tipoServicoAction.getTiposServico({ Page: 0, Limit: 100, IsProjeto: false }))
      }
    }
  }


  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{props.obra.ultimoEvento == STATUS_SERVICO.LANCAMENTO ? 'Novo Item' : 'Itens'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Item'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (props.obraEtapaItem.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (

    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid container spacing={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center'
        }}>
        <Grid item lg={3} md={3} xl={3} xs={6}>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formPesq"
            onSubmit={(event) => handleSubmit(event)}
          >
            {(undefined != obraEtapa && obraEtapa.length > 0 ?
              <SelectValidator
                variant="outlined"
                id="idObraEtapa"
                label="Grupo"
                margin="normal"
                value={idObraEtapa}
                onChange={(e) => setIdObraEtapa(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                displayEmpty
                labelId="idObraEtapa"
              >
                <MenuItem disabled value="">
                  <em>Etapa</em>
                </MenuItem>
                {!isEmpty(obraEtapa) &&
                  undefined !== obraEtapa &&
                  obraEtapa.length &&
                  obraEtapa.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.titulo}
                    </MenuItem>
                  ))}
              </SelectValidator>
              :
              null
            )}
          </ValidatorForm>
        </Grid>
        {(undefined != obraEtapa && obraEtapa.length > 0 &&
          <>
            <Grid item lg={4} md={4} xl={4} xs={6}>
              <Typography variant="overline" display="block">
                Valor Total de Referência dos Itens da Etapa:
              </Typography>
              <Typography variant="subtitle2">
                <b>{handleCalculaValorReferenciaTotal() ? handleCalculaValorReferenciaTotal() : '--,--'}</b>
              </Typography>
            </Grid>
            {isObra &&
              <>
                <Grid item lg={3} md={3} xl={3} xs={6}>
                  <Typography variant="overline" display="block">
                    Valor Total da Obra com BDI:
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>{valorReferenciaComBdi ? Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(valorReferenciaComBdi) : 'R$ --,--'}</b>
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={6} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                  <div>
                    <Typography variant="overline">
                      Valor do BDI:
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>{bdi ? bdi : 0} %</b>
                    </Typography>
                  </div>
                  <Tooltip
                    style={{ paddingLeft: 4 }}
                    title="Percentual que será acrescido ao valor total da obra"
                  >
                    <InfoIcon fontSize='small' sx={{ verticalAlign: 'middle', marginTop: 1 }} />
                  </Tooltip>
                </Grid>
              </>
            }
          </>
        )}
      </Grid>
      <Divider />
      {(props.obra.ultimoEvento === STATUS_SERVICO.LANCAMENTO) &&
        <>
          <Grid item md={12} xs={12}>
            <ValidatorForm
              className={classes.form}
              ref={formRef}
              id="formEtapa"
              onSubmit={(event) => handleSubmit(event)}
            >
              <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CardHeader
                  subheader="Cadastro de Item"
                  title={<SegHeader />}
                />
                <Grid item lg={1} md={1} xl={1} xs={12}>
                  <IconButton
                    aria-label="Ajuda"
                    component="a"
                    onClick={() =>
                      handleOpenModalHelp()
                    }
                    size="small"
                  >
                    <Tooltip title={'Ajuda'}>
                      <Tooltip>
                        <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                      </Tooltip>
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <CardContent lg={12} md={12} xl={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography component="h4" variant="subtitle" align="center" >
                      Informações sobre a tabela de referência ou CPU
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} >
                    <SelectValidator
                      variant="outlined"
                      id="idTabelaInsumo"
                      label="Tabela Padrão *"
                      value={props.obraEtapaItem.idTabelaInsumo || '1'}
                      margin="normal"
                      onChange={handleChange('idTabelaInsumo')} // por padrão vem a SINAPI marcada
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      disabled={isEditable(props.obra.ultimoEvento) == false || props.obraEtapaItem.id}
                      labelId="idTabelaInsumo"
                    >
                      {!isEmpty(tabelaInsumo) &&
                        undefined != tabelaInsumo &&
                        tabelaInsumo.length &&
                        tabelaInsumo.map((row, index) => (
                          <MenuItem key={index} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  {props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ?
                    <>
                      <Grid item lg={2} md={2} xl={2} xs={12} >
                        <SelectValidator
                          className={classes.textField}
                          variant="outlined"
                          id="idEstadoTabelaInsumo"
                          disabled={isEditable(props.obra.ultimoEvento) == false || props.obraEtapaItem.id || (orgaoSelecionado && orgaoSelecionado.estado)}
                          label="UF Tabela *"
                          value={props.obraEtapaItem.idEstadoTabelaInsumo || ''}
                          onChange={handleChange('idEstadoTabelaInsumo')}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          labelId="idEstado"
                        >
                          <MenuItem disabled value="">
                            <em>Estado</em>
                          </MenuItem>
                          {!isEmpty(estados) &&
                            undefined !== estados &&
                            estados.length &&
                            estados.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        {Array.isArray(tabelaInsumoAno) ?
                          <SelectValidator
                            className={classes.textField}
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="anoTabelaInsumo"
                            disabled={isEditable(props.obra.ultimoEvento) == false || props.obraEtapaItem.id}
                            onChange={handleChange('anoTabelaInsumo')}
                            label="Ano Tabela *"
                            value={props.obraEtapaItem.anoTabelaInsumo || ''} // Set initial value here
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            labelId="anoTabelaInsumo"
                          >
                            {!isEmpty(tabelaInsumoAno) &&
                              undefined != tabelaInsumoAno &&
                              tabelaInsumoAno.length &&
                              tabelaInsumoAno.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                          : null}
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        {Array.isArray(tabelaInsumoMes) ?
                          <SelectValidator
                            className={classes.textField}
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="mesTabelaInsumo"
                            disabled={isEditable(props.obra.ultimoEvento) == false || props.obraEtapaItem.id}
                            label="Mês Tabela *"
                            value={props.obraEtapaItem.mesTabelaInsumo}
                            onChange={handleChange('mesTabelaInsumo')}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            labelId="mesTabelaInsumo"
                          >
                            {!isEmpty(tabelaInsumoMes) &&
                              undefined != tabelaInsumoMes &&
                              tabelaInsumoMes.length &&
                              tabelaInsumoMes.map((row, index) => {
                                if (index < 7) {
                                  return (
                                    <MenuItem key={index} value={row.value}>
                                      {row.text}
                                    </MenuItem>
                                  )
                                }
                              })}
                          </SelectValidator>
                          : null}
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <SelectValidator
                          variant="outlined"
                          id="onerado"
                          value={props.obra.onerado}
                          label="Desonerado"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          labelId="onerado"
                          displayEmpty
                          disabled
                        >
                          <MenuItem disabled value="">
                            <em>Desonerado</em>
                          </MenuItem>
                          <MenuItem value={true}>NÃO DESONERADO</MenuItem>
                          <MenuItem value={false}>DESONERADO</MenuItem>
                        </SelectValidator>
                      </Grid>
                    </>
                    : null
                  }
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography component="h4" variant="subtitle" align="center" >
                      {props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ? 'Clique sobre a lupa para selecionar um item' :
                        'Digite as informações do item'}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      id="codigo"
                      label="Código"
                      disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      margin="normal"
                      onChange={handleChange('codigo')}
                      value={props.obraEtapaItem.codigo || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ? 8 : 9}
                    md={props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ? 8 : 9}
                    xl={props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ? 8 : 9} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      id="descritivo"
                      label="Descritivo"
                      margin="normal"
                      disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      onInput={stringUtils.toInputUppercase}
                      onChange={handleChange('descritivo')}
                      value={props.obraEtapaItem.descritivo || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ?
                    <Grid item lg={1} md={1} xl={1} xs={12}>
                      <Tooltip title="Pesquisar por item">
                        <IconButton
                          style={{ marginTop: 20 }}
                          color="inherit"
                          disabled={isEditable(props.obra.ultimoEvento) == false}
                          onClick={() => handleOpenModal()}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    : null}
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <SelectValidator
                      variant="outlined"
                      id="idTipoServico"
                      label="Tipo de Serviço"
                      margin="normal"
                      disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      onChange={handleChange('idTipoServico')}
                      value={props.obraEtapaItem.idTipoServico || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      required
                      displayEmpty
                      labelId="idTipoServico"
                    >
                      <MenuItem disabled value="">
                        <em>Tipo de Serviço *</em>
                      </MenuItem>
                      {!isEmpty(tipoServico) &&
                        undefined !== tipoServico &&
                        tipoServico.length &&
                        tipoServico.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.nome}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <SelectValidator
                      variant="outlined"
                      id="idUnidade"
                      label="Unidade"
                      margin="normal"
                      disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      onChange={handleChange('idUnidade')}
                      value={props.obraEtapaItem.idUnidade || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      required
                      labelId="idUnidade"
                    >
                      <MenuItem disabled value="">
                        <em>Unidade</em>
                      </MenuItem>
                      {!isEmpty(unidade) &&
                        undefined !== unidade &&
                        unidade.length &&
                        unidade.map((row) => (
                          <MenuItem key={row.value} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      type="number"
                      disabled={isEditable(props.obra.ultimoEvento) == false}
                      id="quantidade"
                      label="Quantidade *"
                      margin="normal"
                      onChange={handleChange('quantidade')}
                      value={props.obraEtapaItem.quantidade || ''}
                      variant="outlined"
                      validators={['required']}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        min: 0,
                        step: 0.001
                      }}
                      errorMessages={['Campo obrigatório!']}
                    />
                  </Grid>
                  {/*            se não for CPU é obrigatório lançar um valor*/}
                  {props.obraEtapaItem.idTabelaInsumo != 3 && props.obraEtapaItem.idTabelaInsumo != 4 ?
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="valorReferencia"
                        label="Valor Referência Unidade"
                        margin="normal"
                        type="text"
                        onChange={handleChange('valorReferencia')}
                        value={props.obraEtapaItem.valorReferencia ? (props.obraEtapaItem.valorReferencia).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                        variant="outlined"
                        validators={['required']}
                        required
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R$
                            </InputAdornment>
                          )
                        }}
                        errorMessages={['Campo obrigatório!']}
                        disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      />
                    </Grid>
                    :
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="valorReferencia"
                        label="Valor Referência Unidade"
                        margin="normal"
                        type="text"
                        onChange={handleChange('valorReferencia')}
                        value={props.obraEtapaItem.valorReferencia ? (props.obraEtapaItem.valorReferencia).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R$
                            </InputAdornment>
                          )
                        }}
                        disabled={isEditable(props.obra.ultimoEvento) == false || disabledEdicao}
                      />
                    </Grid>
                  }
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                    * Campos obrigatórios
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                  display: 'flex',
                  alignItems: 'right',
                  flexDirection: 'row',
                  textAlgin: 'right',
                  justifyContent: 'right'
                }}>
                  <CustomButton
                    variant="outlined"
                    onClick={() => handleClean()}
                  >
                    Cancelar
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    dark={true}
                  disabled={props.obraEtapaItem.isLoading || props.obra.ultimoEvento != STATUS_SERVICO.LANCAMENTO}
                    startIcon={<SaveIcon />}
                    endIcon={props.obraEtapaItem.isLoading && <CircularProgress size={24} />}
                  >
                    {props.obraEtapaItem.id ? (props.obraEtapaItem.isLoading ? 'Atualizando Item...' : 'Atualizar') : (props.obraEtapaItem.isLoading ? 'Salvando Item...' : 'Salvar')}
                  </CustomButton>
                {(authActions.isGestor() || authActions.isPrefeitura()) && props.obra.ultimoEvento == STATUS_SERVICO.LANCAMENTO &&
                    <Tooltip title="Finaliza o lançamento dos itens, permitindo que a obra passe a receber orçamentos">
                      <span>
                        <CustomButton
                          variant="outlined"
                          onClick={(event) => handleModalConfirm(event)}
                          disabled={props.obraEtapaItem.isLoading || undefined == obraEtapaItem || obraEtapaItem.length == 0}
                          startIcon={<ChecklistRtlIcon />}
                          endIcon={props.obraEtapaItem.isLoading && <CircularProgress size={24} />}
                        >
                          {props.obraEtapaItem.isLoading ? 'Finalizando Lançamentos...' : 'Finalizar Lançamento de itens'}
                        </CustomButton>
                      </span>
                    </Tooltip>
                  }
                  {(authActions.isGestor() || authActions.isPrefeitura()) && props.obra.ultimoEvento == STATUS_SERVICO.PENDENTE &&
                    <Tooltip title="Retorna a obra para o status anterior, o que permite que itens sejam lançados novamente">
                      <span>
                        <CustomButton
                          variant="outlined"
                          onClick={() => handleAlteraStatusParaEmLancamento(idObra)}
                          startIcon={<KeyboardReturnIcon />}
                          endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                        >
                          {props.obra.isLoading ? 'Liberando Lançamentos...' : 'Liberar lançamento de itens'}
                        </CustomButton>
                      </span>
                    </Tooltip>
                  }
                </Grid>
              </CardActions>
            </ValidatorForm>
          </Grid>
        </>
      }
      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell align="center">Tabela</TableCell>
              <TableCell>Descritivo</TableCell>
              <TableCell align="right">Quantidade</TableCell>
              <TableCell align="right">Valor Referência Unidade</TableCell>
              <TableCell align="right">Valor Total + BDI</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraEtapaItem && obraEtapaItem.length > 0
              ? obraEtapaItem.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.codigo ? n.codigo : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {
                        n.idTabelaInsumo != 3 && n.idTabelaInsumo != 4 ? //SE NÃO FOR CPU E COMPRA DE MATERIAL
                          (
                            (tabelaInsumo != null && Array.isArray(tabelaInsumo) && tabelaInsumo.length > 0 && n.idTabelaInsumo != '' &&
                              tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0] != null
                            )
                              ? tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0].text
                              : ''
                          ) +
                          ' - ' +
                          (
                            (estado != null && Array.isArray(estado) && estado.length > 0 && n.idEstadoTabelaInsumo != 0 &&
                              estado.filter(obj => obj.value == n.idEstadoTabelaInsumo)[0] != null
                            )
                              ? estado.filter(obj => obj.value == n.idEstadoTabelaInsumo)[0].text
                              : ''
                          ) +
                          ' (' +
                          (
                            (tabelaInsumoAno != null && Array.isArray(tabelaInsumoAno) && tabelaInsumoAno.length > 0 && n.anoTabelaInsumo != '' &&
                              tabelaInsumoAno.filter(obj => obj.value == n.anoTabelaInsumo)[0] != null
                            )
                              ? tabelaInsumoAno.filter(obj => obj.value == n.anoTabelaInsumo)[0].text
                              : ''
                          ) +
                          '/' +
                          (
                            (tabelaInsumoMes != null && Array.isArray(tabelaInsumoMes) && tabelaInsumoMes.length > 0 && n.mesTabelaInsumo != '' &&
                              tabelaInsumoMes.filter(obj => obj.value == n.mesTabelaInsumo)[0] != null
                            )
                              ? tabelaInsumoMes.filter(obj => obj.value == n.mesTabelaInsumo)[0].text
                              : ''
                          ) +
                          ')' :
                          <Tooltip title='Composição de preço unitário'>
                            <span>{(tabelaInsumo != null && Array.isArray(tabelaInsumo) && tabelaInsumo.length > 0 && n.idTabelaInsumo != 0 &&
                              tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0] != null
                            )
                              ? tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0].text
                              : ''}
                            </span>
                          </Tooltip>
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.descritivo ? n.descritivo.toUpperCase() : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {n.quantidade}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {n.valorReferencia ? Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(n.valorReferencia) : '--,--'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {n.valorReferenciaComBdi ? Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(n.valorReferenciaComBdi) : '--,--'}
                    </TableCell>
                    {(props.obra.ultimoEvento == STATUS_SERVICO.LANCAMENTO) &&
                      <TableCell align="right">
                        <AccessControl
                          rule={'obrasEtapasItens.edit'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <IconButton
                              aria-label="Edit"
                              component="a"
                              onClick={() => handleEdit(n.id)}
                              size="small"
                            >
                              <Tooltip title={'Editar'}>
                                <Tooltip >
                                  <EditIcon />
                                </Tooltip>
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                        <AccessControl
                          rule={'obrasEtapasItens.delete'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <IconButton
                              aria-label="Delete"
                              onClick={() => handleDelete(n.id)}
                              size="small"
                            >
                              <Tooltip title="Excluir">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                      </TableCell>
                    }
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />


      {
        modalConfirm ?
          <Dialog
            open={modalConfirm}
            fullWidth
            style={{ padding: 0, margin: 0 }}
            maxWidth='xl'
            onClose={() => setModalConfirm(false)}
          >
            <DialogTitle>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>
                CONFIRMAÇÃO DE LANÇAMENTOS DE ITENS DA OBRA
              </Typography>
              <Button
                variant="text"
                style={{ position: 'absolute', top: 0, right: 0, color: '#000', minWidth: 'unset' }}
                onClick={() => setModalConfirm(false)}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
                <Grid item display="flex" flexDirection="column" justifyContent="center">
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} >O BDI da obra é de: {bdi} %</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} >Os itens lançados para a obra são:</CustomTypography>
                    </CustomGrid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Código</TableCell>
                          <TableCell align="center">Tabela</TableCell>
                          <TableCell>Descritivo</TableCell>
                          <TableCell align="right">Quantidade</TableCell>
                          <TableCell align="right">Valor de Referência</TableCell>
                          <TableCell align="right">Valor com BDI</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {undefined !== itensObra && itensObra.length > 0
                          ? itensObra.map((n) => {
                            return (
                              <TableRow hover key={n.id}>
                                <TableCell component="th" scope="row">
                                  {n.codigo ? n.codigo : ''}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                  {(tabelaInsumo != null && tabelaInsumo != undefined && tabelaInsumo.length > 0 && n.idTabelaInsumo != '' && tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0] != null ?
                                    tabelaInsumo.filter(obj => obj.value == n.idTabelaInsumo)[0].text
                                    : '') + ' - ' + (estado != null && estado != undefined && estado.length > 0 && n.idEstadoTabelaInsumo != '' && estado.filter(obj => obj.value == n.idEstadoTabelaInsumo)[0] != null ?
                                      estado.filter(obj => obj.value == n.idEstadoTabelaInsumo)[0].text
                                      : '') + ' (' + (tabelaInsumoAno != null && tabelaInsumoAno != undefined && tabelaInsumoAno.length > 0 && n.anoTabelaInsumo != '' && tabelaInsumoAno.filter(obj => obj.value == n.anoTabelaInsumo)[0] != null ?
                                        tabelaInsumoAno.filter(obj => obj.value == n.anoTabelaInsumo)[0].text
                                        : '') + '/' + (tabelaInsumoMes != null && tabelaInsumoMes != undefined && tabelaInsumoMes.length > 0 && n.mesTabelaInsumo != '' && tabelaInsumoMes.filter(obj => obj.value == n.mesTabelaInsumo)[0] != null ?
                                          tabelaInsumoMes.filter(obj => obj.value == n.mesTabelaInsumo)[0].text
                                          : '') + ')'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {n.descritivo ? n.descritivo.toUpperCase() : ''}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  {n.quantidade}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  }).format(n.valorReferencia * n.quantidade)}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  }).format((n.valorReferencia * n.quantidade) + (props.obra.bdi ? (n.valorReferencia * n.quantidade * (props.obra.bdi / 100)) : 0))}
                                </TableCell>
                              </TableRow >
                            )
                          })
                          :
                          (
                            !isLoading ?
                              <TableRow>
                                <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                              </TableRow>
                              :
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <Grid item xl={4} lg={4} md={4} xs={12} align="right">
                      <Typography variant="overline" >
                        Valor BDI:
                      </Typography>
                      <Tooltip title="Percentual que será acrescido ao valor total da obra">
                        <Typography variant="subtitle2">
                          <b>{props.obra.bdi} %</b>
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} xs={12} align="right">
                      <Typography variant="overline">
                        <strong>Valor Total da Obra</strong>
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>{handleCalculaValorReferenciaTotalObraSemBdi() ? handleCalculaValorReferenciaTotalObraSemBdi() : 'R$ -,--'}</b>
                      </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} xs={12} align="right">
                      <Typography variant="overline">
                        <strong>Valor Total da Obra Com BDI:</strong>
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>{handleCalculaValorReferenciaTotalObra() ? handleCalculaValorReferenciaTotalObra() : 'R$ -,--'}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    onClick={() => handleFinalizaLancamentoItens(idObra)}
                    variant="contained"
                    style={{ backgroundColor: '#000', color: '#FFF', marginTop: 16 }}
                    startIcon={<SaveIcon />}
                    endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                  >
                    {(props.obra.isLoading ? 'Finalizando Lançamento...' : 'Finalizar Lançamento de Itens')}
                  </Button>
                </Grid>
              </Card>
            </DialogContent>
          </Dialog >
          : null
      }

      {
        (modal ?
          <InsumoModal
            toggle={toggle}
            modal={modal}
            props={props}
            id={modalId}
            ano={props.obraEtapaItem.anoTabelaInsumo}
            mes={props.obraEtapaItem.mesTabelaInsumo}
            uf={(estado != null && estado != undefined && estado.length > 0 && props.obraEtapaItem.idEstadoTabelaInsumo != '' && estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0] != null ?
              estado.filter(obj => obj.value == props.obraEtapaItem.idEstadoTabelaInsumo)[0].text
              : ''
            )}
            tipoTabela={(tabelaInsumo != null && tabelaInsumo != undefined && tabelaInsumo.length > 0 && props.obraEtapaItem.idTabelaInsumo != '' && tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0] != null ?
              tabelaInsumo.filter(obj => obj.value == props.obraEtapaItem.idTabelaInsumo)[0].text
              : ''
            )}
            onerado={props.obraEtapaItem.onerado}
          />
          :
          null
        )
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_ITEM}
          />
          :
          null
        )
      }
    </Grid >

  )
}

ObraEtapaItemEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraEtapaItemEdit))
)
export { connectedObraEditPage as ObraEtapaItemEdit }
