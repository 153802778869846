import React, { useEffect, useState } from 'react';
import moment from 'moment'
import SquareIcon from '@mui/icons-material/Square';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import HelpIcon from '@mui/icons-material/Help';
import {
  Button,
  CardActions,
  CardHeader,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Typography,
  Tooltip
} from '@mui/material'
import { obraOrcamentoItemAction, authActions, obraOrcamentoAction, relatorioAction, obraEtapaItemAction } from '../../_actions';
import { connect, useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import DoneIcon from '@mui/icons-material/Done';
import { withRouter, } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MANUAIS_CATEGORIAS, STATUS_SERVICO } from '../../utils'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@mui/styles'
import { Box } from '@material-ui/core';
import Stars from '../../components/Stars/Stars';
import CustomButton from '../../components/CustomButton';
import { HelpModal } from '../../components';
import { ObraMotivoModal } from '../ObrasOrcamentos/obraMotivoModal.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ObraOrcamentoItemEdit = ({ props, idObra, obra }) => {

  const { isLoading } = props.obraOrcamento;
  const horasRecebimentoOrcamentos = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.horasRecebimentoOrcamentos : undefined))
  const [dataLimiteRecebimentoOrcamentos, setDataLimiteRecebimentoOrcamentos] = useState('');

  const [modalOrcamentoMotivo, setModalOrcamentoMotivo] = useState(false);
  const [valorOrcamentoAprovado, setValorOrcamentoAprovado] = useState(null);
  const [idOrcamentoAprovado, setIdOrcamentoAprovado] = useState(null);
  const toggleModalOrcamentoMotivo = () => setModalOrcamentoMotivo(!modalOrcamentoMotivo);

  const isObra = props.obra.tipoObra.obra;
  const textObra = isObra ? 'Obra' : 'Compra de Material';

  const [ready, setReady] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const [itensAprovados, setItensAprovados] = useState([])

  const { obrasEtapasItens } = useSelector(
    (state) => state.obraOrcamentoItem.obraOrcamentoItem)

  const { obrasOrcamentos } = useSelector(
    (state) => state.obraOrcamentoItem.obraOrcamentoItem)

  useEffect(() => {
  }, [ready, isLoading])

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const handleOpenModalMotivo = () => {
    toggleModalOrcamentoMotivo();
  }

  useEffect(() => {

    let filterModel = {
      Id: idObra
    };

    dispatch(obraOrcamentoItemAction.getObraOrcamentoItemObra(filterModel))

    if (obra && obra.horasRecebimentoOrcamentos && obra.horasRecebimentoOrcamentos != 0) {
      const dataLimite = moment(obra.dataAprovacao).add(obra.horasRecebimentoOrcamentos, 'hours').toISOString()
      setDataLimiteRecebimentoOrcamentos(dataLimite)
    }

  }, [])

  function isMenorPreco(obj, idObraOrcamento) {

    var min = obj.obrasOrcamentosItens.reduce(function (prev, curr) {
      return prev.valor < curr.valor ? prev : curr;
    });

    if (min && min.idObraOrcamento == idObraOrcamento && min != 0 && min != '') {
      return true;
    } else {
      return false;
    }
  }

  const isOrcamentoAtrasado = (dataOrcamento, dataObra) => {
    if (dataObra && dataObra != undefined) {
      if (obra.horasRecebimentoOrcamentos != null && obra.horasRecebimentoOrcamentos != 0 && moment(dataOrcamento).diff(moment(dataObra), 'hours') > obra.horasRecebimentoOrcamentos) {
        return true;
      } else if (horasRecebimentoOrcamentos != null && horasRecebimentoOrcamentos != 0 && horasRecebimentoOrcamentos != '') {
        if (moment(dataOrcamento).diff(moment(dataObra), 'hours') > horasRecebimentoOrcamentos) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  const handleExportExcel = () => {

    let filterModel = {
      Id: idObra
    };

    dispatch(relatorioAction.getRelatorioOrcamentosArquivo(filterModel))
  }

  const isItemSelected = (id) => {
    if (id != undefined) {
      return obrasEtapasItens?.some((n) => n.idObraOrcamentoItem == id && n.idObraOrcamentoItem != null)
    }
  }
  function isItensNaoAprovados() {
    var teste = obrasEtapasItens?.some((n) => n.idObraOrcamentoItem == null)
    return teste;
  }

  const handleAprovaOrcamentoItem = (id) => {
    if (isItemSelected(id)) {
      if (id && id != undefined) {
        dispatch(obraOrcamentoItemAction.editObraOrcamentoItemAprova(id, idObra));
      }
    } else {
      confirm({
        title: 'Você deseja aprovar o orçamento deste item?',
        description: 'O prestador de serviço será notificado desta ação posteriormente',
        disabled: isLoading,
        confirmationText: isLoading ? 'Aprovando Orçamento...' : 'Sim, aprovar!',
        cancellationText: 'Não!'
      }).then(() => {
        if (id && id != undefined) {
          dispatch(obraOrcamentoItemAction.editObraOrcamentoItemAprova(id, idObra));
        }
      })
    }
  }

  const handleClean = () => {
    dispatch(obraOrcamentoAction.clearDetail())
  }

  const handleAprovaOrcamento = () => {
    confirm({
      title: 'Você deseja realmente aprovar este Orçamento?',
      description: 'Os demais orçamentos recebidos serão automaticamente marcados como recusados',
      disabled: props.obraOrcamento.isLoading,
      confirmationText: props.obraOrcamento.isLoading ? 'Aprovando Orçamento...' : 'Sim, aprovar!',
    }).then(() => {
      handleOpenModalMotivo(itensAprovados, true)
    })
  }

  const handleExpand = (index) => {
    if (expanded.some(e => e === index)) {
      const newExpanded = expanded.filter(e => e !== index);
      setExpanded(newExpanded);
    } else {
      const newExpanded = [...expanded, index];
      setExpanded(newExpanded);
    }
  }

  const handleClickEmpresaOrcamento = (idOrcamento, valorOrcamento) => {

    let itensAprovadosEmpresa = [];

    if (idOrcamentoAprovado == idOrcamento) {
      // Desmarque o checkbox se o mesmo ID for clicado novamente
      setIdOrcamentoAprovado(null);
      setValorOrcamentoAprovado(null);
      setItensAprovados([]);
      return;
    }

    if (valorOrcamento) {
      setValorOrcamentoAprovado(valorOrcamento)
    }

    if (idOrcamento) {
      setIdOrcamentoAprovado(idOrcamento);

      obrasEtapasItens.map((n) => {
        n.obrasOrcamentosItens.map((n1) => {
          if (n1.idObraOrcamento == idOrcamento) {
            if (n1.status == '2' || n1.status == null) {
              n1.status = '1';
              n.idObraOrcamentoItem = n1.id;
              itensAprovadosEmpresa.push(n1.id);
            } else {
              n1.status = '2';
              n.idObraOrcamentoItem = null;
              itensAprovadosEmpresa = [];
            }
          }
        })
      })

      setItensAprovados(itensAprovadosEmpresa);

      let changeEtapasItensEvent = {
        type: 'text',
        target: {
          value: obrasEtapasItens
        }
      };

      dispatch(obraEtapaItemAction.onChangeProps('obraEtapaItem', changeEtapasItensEvent));

    }
  }

  const canRevert = () => {
    const isAdmin = props.authentication.user.idTipoUsuario == 1;
    const isInExec = props.obra.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO;
    if (isInExec == false || isAdmin == false) {
      return false;
    }

    const arrEvent = props.obra.eventos.filter(i => i.status === '3').sort((a, b) => a.data > b.data ? -1 : 1);
    const event = isInExec ? (arrEvent.length > 0 ? arrEvent[0] : null) : null;
    const isMoreThanDay = event ? moment().diff(moment(event?.data), 'minutes') > (24 * 60) : null;
    const hasMedicao = props.obraMedicao.obraMedicao.some(m => m.situacao = 1);

    return isAdmin && isInExec && isMoreThanDay && !hasMedicao;
  }

  const handleRevert = () => {
    confirm({
      title: 'Você deseja reverter a aprovação dos orçamentos dessa obra?',
      description: 'Essa é uma situação crítica, só faça se tiver total certeza! A obra voltará para o status de recebendo orçamento.',
      disabled: props.obraOrcamento.isLoading,
      confirmationText: props.obraOrcamento.isLoading ? 'Revertendo...' : 'Sim, reverter!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraOrcamentoAction.revert(props.obra.id));
    })
  }

  function TitleText() {
    return (
      <Typography variant="h5">
        <b>{'Orçamentos de itens para aprovação'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <TitleText />
  }

  return (
    <>
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignItems: 'center', marginTop: 3 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CardHeader
            subheader="Aprovar o orçamento para determinado item"
            title={<SegHeader />}
          />
          <Typography variant="subtitle1" gutterBottom style={{ marginLeft: 15 }}>
            <strong>{obra && obra.dataAprovacao ? `Data de aprovação da obra: ${moment(obra.dataAprovacao).format('DD/MM/YYYY HH:mm')}` : null}</strong>
          </Typography>
          <Typography variant="subtitle1" gutterBottom style={{ marginLeft: 15 }}>
            <strong>Data limite para o recebimento de orçamentos: {dataLimiteRecebimentoOrcamentos ? moment(dataLimiteRecebimentoOrcamentos).format('DD/MM/YYYY HH:mm') : '-'}</strong>
          </Typography>
        </div>
        <Grid item lg={1} md={1} xl={1} xs={12}>
          <IconButton
            aria-label="Ajuda"
            component="a"
            onClick={() =>
              handleOpenModalHelp()
            }
            size="small"
          >
            <Tooltip title={'Ajuda'}>
              <Tooltip>
                <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
              </Tooltip>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item lg={4} md={4} xl={3} xs={12} align="center">
          <Typography variant="overline" display="block">
            Valor Total da {textObra} com BDI:
          </Typography>
          <Typography variant="subtitle2">
            <b>{props.obra.valorReferenciaComBdi ? Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(props.obra.valorReferenciaComBdi) : (isObra ? 'R$ 0,00' : '--,--')}</b>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            style={{ borderColor: '#000', color: '#000' }}
            type="button"
            size="small"
            onClick={() => handleExportExcel()}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
          >
            Exportar para Excel
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={obrasOrcamentos?.length + 3}>
                <Grid container spacing={2} style={{ justifyContent: 'end' }}>
                  <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="subtitle2"><strong>Legenda:&nbsp;</strong></Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <SquareIcon sx={{ color: '#8DE3C4', borderColor: '#8DE3C4' }} />&nbsp; <Typography variant="subtitle2">Item de menor valor</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <SquareIcon sx={{ color: '#99bbff', borderColor: '#99bbff', marginLeft: 2 }} />&nbsp;<Typography variant="subtitle2">Item aprovado</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow size="small">
              <TableCell size="small" style={{ width: '25%' }}>
                <strong>ITEM</strong>
              </TableCell>
              <TableCell size="small" style={{ textAlign: 'center', width: '15%' }} align="right">
                <strong>VALOR REF.</strong>
              </TableCell>
              <TableCell size="small" style={{ textAlign: 'center', width: '10%' }} align="center">
                <strong>QUANT/UN</strong>
              </TableCell>
              {obrasOrcamentos && obrasOrcamentos != undefined && obrasOrcamentos.map((n, i) => (
                <TableCell size="small" align="right" style={{ textAlign: 'center', backgroundColor: n.status == 1 ? '#99BBFF' : obrasOrcamentos.some(o => o.valor < n.valor) ? '#fff' : '#8DE3C4' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Tooltip title="Marcar todos os itens desta empresa">
                      <Checkbox
                        onClick={() => handleClickEmpresaOrcamento(n.id, n.valor)}
                        color="primary"
                        checked={idOrcamentoAprovado == n.id}
                        disabled={obra.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || obra.ultimoEvento == STATUS_SERVICO.RECUSADO || obra.ultimoEvento == STATUS_SERVICO.CONCLUIDO || obra.ultimoEvento == STATUS_SERVICO.AVALIADO}
                        size="small"
                      />
                    </Tooltip>
                    <div>
                      <span style={{ fontSize: 12 }}><strong>{n.nomeFantasia}</strong></span>
                      <div><Stars nota={n.mediaNota} avaliacoes={n.totalAvaliacoes} /></div>
                      <Tooltip title={isOrcamentoAtrasado(n.data, n.dataAprovacao) ? 'Orçamento enviado após o prazo' : null} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', color: isOrcamentoAtrasado(n.data, n.dataAprovacao) ? '#EF5350' : '#000', fontWeight: isOrcamentoAtrasado(n.data, n.dataAprovacao) ? 'bold' : 'normal' }}>
                          <span style={{ fontSize: 11 }}>Envio:</span>
                          <span style={{ fontSize: 11 }}>&nbsp;{n.data ? moment(n.data).format("DD/MM/YYYY") : ''}</span>
                        </div>
                      </Tooltip>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <span style={{ fontSize: 11 }}>Prazo:</span>
                        <span style={{ fontSize: 11 }}>&nbsp;{n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <span style={{ fontSize: 11 }}>Total:</span>
                        <span style={{ fontSize: 11 }}>&nbsp;{n.valor ? Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(n.valor) : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {obrasEtapasItens && obrasEtapasItens.length > 0 ? (
              obrasEtapasItens.reduce((acc, n) => {
                const existingRow = acc.find(row => row.idObraEtapa === n.idObraEtapa);
                if (existingRow) {
                  const existingItem = existingRow.items.find(item => item.descritivo === n.descritivo);
                  if (existingItem) {
                    existingItem.subItems.push(n);
                  } else {
                    existingRow.items.push({
                      descritivo: n.descritivo,
                      subItems: [n]
                    });
                  }
                } else {
                  acc.push({ idObraEtapa: n.idObraEtapa, items: [{ descritivo: n.descritivo, subItems: [n] }] });
                }
                return acc;
              }, []).map((row, index) => (
                <React.Fragment key={row.idObraEtapa}>
                  <TableRow size="small" style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => handleExpand(index)}>
                    <TableCell colSpan={obrasOrcamentos?.length + 3} style={{ backgroundColor: '#f2f2f2' }} align="left">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="p" fontWeight="bold">{row.items[0].subItems[0].obraEtapa?.titulo}</Typography>
                        {expanded.some(e => e == index) ? <ExpandLess /> : <ExpandMore />}
                      </Box>
                    </TableCell>
                  </TableRow>
                  {expanded.some(e => e == index) && row.items.map(item => (
                    <React.Fragment key={item.descritivo}>
                      {item.subItems.map(n => (
                        <TableRow key={n.id}>
                          <>
                            <Tooltip title={n.descritivo ? n.descritivo : null}>
                              <TableCell style={{ alignItems: 'center', fontSize: 12, width: '25%' }}>
                                {n.descritivo ? n.descritivo.substring(0, 80) : ''}
                              </TableCell>
                            </Tooltip>
                            <TableCell style={{ alignItems: 'right', fontSize: 12, width: '15%' }} align="right">
                              R$ {n.valorReferenciaComBdi ? (n.valorReferenciaComBdi).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                            </TableCell>
                            <TableCell style={{ alignItems: 'center', fontSize: 12, width: '10%' }} align="center">
                              {n.quantidade} {n.unidade ? n.unidade.descricao : ''}
                            </TableCell>
                          </>
                          {
                            obrasOrcamentos && obrasOrcamentos != undefined && obrasOrcamentos.map((n1, i) => (
                              <TableCell style={{
                                minWidth: 100,
                                justifyContent: 'end',
                                backgroundColor:
                                  isItemSelected(n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.id)
                                    ? '#99bbff'
                                    : isMenorPreco(n, n1.id)
                                      ? '#8DE3C4'
                                      : '#FFFFFF',
                                alignItems: 'center'
                              }}>     <Tooltip title={n1.observacoes ? n1.observacoes : null}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.valor &&
                                      <Checkbox
                                        onClick={() => handleAprovaOrcamentoItem(n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.id)}
                                        color="primary"
                                        disabled={obra.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || obra.ultimoEvento == STATUS_SERVICO.RECUSADO || obra.ultimoEvento == STATUS_SERVICO.CONCLUIDO || obra.ultimoEvento == STATUS_SERVICO.AVALIADO}
                                        size="small"
                                        checked={isItemSelected(n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.id)} //passa o Id do item pra testar se está selecionado já ou não
                                      />
                                    }
                                    <span style={{ fontSize: 12, justifyContent: 'end' }}>{n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.valor ? 'R$ ' + (n.obrasOrcamentosItens?.filter((o) => o.idObraOrcamento == n1.id)[0]?.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</span>
                                  </div>
                                </Tooltip>
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))
                  }
                </React.Fragment>
              ))
            ) : !isLoading ? (
              <TableRow>
                <TableCell colSpan={obrasOrcamentos?.length + 3} align="center">
                  Sem itens para exibir
                </TableCell>
              </TableRow>
            ) : (
              Array.from({ length: 5 }, (_, i) => (
                <TableRow key={i} hover>
                  <TableCell colSpan={obrasOrcamentos?.length + 3}>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell colSpan={obrasOrcamentos?.length + 3}>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell colSpan={obrasOrcamentos?.length + 3}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          {obrasEtapasItens && obrasEtapasItens.length > 0 &&
            <TableBody>
              <TableRow style={{ backgroundColor: '#e6e6e6' }}>
                <TableCell colSpan={3} style={{ fontSize: 12 }}>
                  <strong>VALOR TOTAL DOS ITENS APROVADOS DA EMPRESA</strong>
                </TableCell>
                {obrasOrcamentos &&
                  obrasOrcamentos?.map((n1, i) => {
                    const totalValue = obrasEtapasItens
                      .map(o => o.obrasOrcamentosItens?.find(i => i.idObraOrcamento == n1.id && i.status == 1)?.valor || 0)
                      .reduce((accumulator, value) => accumulator + value, 0);

                    const formattedValue = new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(totalValue);

                    return (
                      <TableCell align="right" key={i}>
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                          <strong>{formattedValue}</strong>
                        </div>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer >
      {canRevert() &&
        <CardActions>
          <Grid item lg={12} md={12} xl={12} xs={12} style={{
            display: 'flex',
            alignItems: 'right',
            flexDirection: 'row',
            textAlign: 'right',
            justifyContent: 'right'
          }}>
            <CustomButton
              style={{ backgroundColor: '#990000', color: '#fff', paddingLeft: '20px', paddingRight: '20px', marginTop: '10px' }}
              onClick={() => handleRevert()}
            >
              <Typography variant="p" color="#fff">Reverter</Typography>
            </CustomButton>
          </Grid>
        </CardActions>
      }
      {
        obra.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS &&
          obrasEtapasItens && obrasEtapasItens != undefined && obrasEtapasItens.length &&
          (authActions.isGestor() || authActions.isPrefeitura()) ?
          <CardActions>
            <Grid item lg={12} md={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlign: 'right',
              justifyContent: 'right'
            }}>
              <CustomButton
                variant="outlined"
                onClick={() => handleClean()}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                dark={true}
                onClick={handleAprovaOrcamento}
                disabled={obra.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || isLoading}
                startIcon={<DoneIcon />}
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                Aprovar orçamento
              </CustomButton>
            </Grid>
          </CardActions>
          : null
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_ORCAMENTO}
          />
          :
          null
        )
      }

      {
        (modalOrcamentoMotivo ?
          <ObraMotivoModal
            toggle={toggleModalOrcamentoMotivo}
            modal={modalOrcamentoMotivo}
            props={props}
            itensAprovados={itensAprovados}
            isItensNaoAprovados={isItensNaoAprovados}
            idObra={idObra}
            valorOrcamentoAprovado={valorOrcamentoAprovado}
          />
          :
          null
        )
      }
    </>
  );
};

ObraOrcamentoItemEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraOrcamentoItemEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraOrcamentoItemEdit))
)
export { connectedObraOrcamentoItemEditPage as ObraOrcamentoItemEdit }

export default ObraOrcamentoItemEdit;
